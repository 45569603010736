.footer {
  width: 100%;
  height: 200px;
  background-color: #121619;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding-top: 10px;
  position:relative;
  left:0;
  bottom:0;
  right:0;
}

.socialMedia svg {
  color: white;
  margin: 20px;
  font-size: 70px;
  cursor: pointer;
}

.footer p {
  color: white;
}